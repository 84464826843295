import React from 'react'
import { Link } from 'gatsby'

import Logo from '../assets/images/logo.svg'
import { Close } from './icons.js'

const Header = () => (
  <header className='header'>
    <div className='header__inner'>
      <Link to='https://www.msatraining.edu.au/' className='header__logo' title="MSA Training">
        <img src={Logo} alt="MSA Training" />
      </Link>
      <div className='header__contact'>
        <span>Contact Us</span>
      	<a className='btn' href='tel:+61399053180'>
          +61 3 9905 3180
        </a>
      </div>
      <div className='header__close'>
        <Link to='/'><Close color='#122B4E' /></Link>
      </div>
    </div>
  </header>
)

export default Header
