import React, { Component } from 'react'

import { Instagram, Facebook, LinkedIn } from './icons'

import Logo from '../assets/images/footer-logo.svg'

class Footer extends Component {

  _scrollTo = (element) => {
    document.querySelector(element).scrollIntoView({ 
      behavior: 'smooth' 
    })
  }

  render() {

    let props = this.props

    return (
      <>
        <footer className='footer'>
          <div className='footer__inner'>
            <div className='footer__col'>
              <img src={Logo} alt='Stage School Australia' />
            </div>
            <div className='footer__col'>
              <h4>Contact Us</h4>
              <p>T <a href='tel:+61399053180'>+61 3 9905 3180</a><br />
              E <a href='mailto:msa-monashtraining@monash.edu'>msa-monashtraining@monash.edu</a></p>
              <p>Room 1.59, Level 1, Campus Centre<br />
              21 Chancellors Walk<br />
              Monash University,<br />
              Clayton Campus VIC 3168</p>
            </div>
            <div className='footer__col'>
              <h4>Opening Hours</h4>
              <p>Mon to Thu: 9am – 4pm<br />
              Fri: 9am – 3pm<br />
              Sat: 10am – 12pm</p>
              <ul>
                <li>Socials</li>
                <li><a href='https://www.instagram.com/msa.tpd/' target='_blank' rel='noopener noreferrer'><Instagram color={'#FFFFFF'} /></a></li>
                <li><a href='https://www.facebook.com/msa.tpd' target='_blank' rel='noopener noreferrer'><Facebook color={'#FFFFFF'} /></a></li>
                <li><a href='https://www.linkedin.com/in/msatpd/' target='_blank' rel='noopener noreferrer'><LinkedIn color={'#FFFFFF'} /></a></li>
              </ul>
            </div>
            <div className='footer__col'>
              <h4>Our Courses</h4>
              <div className='footer__buttons'>
                <span className='btn' onClick={() => this._scrollTo('.lead-form')}>Register Your Interest</span>
                <span className='btn' onClick={() => this._scrollTo('.download-form')}>Download A Course Guide</span>
              </div>
              <p className='footer__credits'>© Copyright 2021 Monash Student Association, All rights reserved — Site. <a href='https://atollon.com.au' target='_blank' rel='noopener noreferrer'>Atollon</a></p>
            </div>
          </div>
        </footer>
      </>
    )
  }
}

export default Footer
